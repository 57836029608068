import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { FormRegistrationTemplateFilters, ApiListResponse, FormRegistrationTemplateListItemDTO, FormRegistrationTemplateStatusDTO, FormRegistrationTemplateDTO, FormRegistrationTemplateFieldAnswerItemDTO, FormRegistrationTemplateTreeNodeDTO } from '@fiba/models';

import { HttpService } from '../utils/http.service';


import { ConfigurationFormRegistrationTemplateFieldAnswerDTO } from '@fiba/models/dtos/generated/configuration-form-registration-template-field-answer-dto';
import { FormRegistrationTemplateActionEnum } from '@fiba/models/constants/generated/form-registration-template-action-enum';
import { AppConfig } from '@app/app.config';
import { FormRegistrationCriteriaListItemDTO } from '../../models/dtos/generated/form-registration-criteria-list-item-dto';
import { FormRegistrationTemplateLinkedEntityListItemDTO } from '../../models/dtos/generated/form-registration-template-linked-entity-list-item-dto';
import { ConfigurationFormRegistrationTemplateFieldDataTypeListItemDTO } from '../../models/dtos/generated/configuration-form-registration-template-field-data-type-list-item-dto';

@Injectable({
    providedIn: 'root',
})
export class FormRegistrationDataService {
    delete(formRegistrationTemplateId: any): any {
        throw new Error('Method not implemented.');
    }

    protected formRegistrationTemplateApiEndpoint: string;

    constructor(protected http: HttpService, protected config: AppConfig) {
        this.formRegistrationTemplateApiEndpoint = config.formRegistrationTemplateApiEndpoint;
    }

    fetchList(filters: FormRegistrationTemplateFilters): Observable<ApiListResponse<FormRegistrationTemplateListItemDTO>> {
        return this.http.get(this.formRegistrationTemplateApiEndpoint, filters.getSearchParams());
    }
    fetchListItemByEntityType(entityTypeItemCode: string): Observable<FormRegistrationTemplateListItemDTO[]> {
        return this.http.get(this.formRegistrationTemplateApiEndpoint + '/' + entityTypeItemCode + '/entity-templates');
    }

    fetchTemplateStatuses(): Observable<FormRegistrationTemplateStatusDTO[]> {
        return this.http.get(this.formRegistrationTemplateApiEndpoint + '/statuses');
    }

    fetchConfigurationFormRegistrationTemplateFieldAnswer(): Observable<ConfigurationFormRegistrationTemplateFieldAnswerDTO[]> {
        return this.http.get(this.formRegistrationTemplateApiEndpoint + '/answer-configurations');
    }

    fetchFormRegistrationTemplate(formRegistrationTemplateId: number): Observable<FormRegistrationTemplateTreeNodeDTO> {
        return this.http.get(this.formRegistrationTemplateApiEndpoint + '/' + formRegistrationTemplateId + '/structure');
    }

    fetchPreviousFormRegistrationTemplate(entityTypeItemId: number): Observable<FormRegistrationTemplateTreeNodeDTO> {
        return this.http.get(this.formRegistrationTemplateApiEndpoint + '/entityType/' + entityTypeItemId + '/previous-structure');
    }

    fetchTemplateLinkedEntityConfiguration(entityTypeItemId: number): Observable<FormRegistrationTemplateLinkedEntityListItemDTO[]> {
        return this.http.get(this.formRegistrationTemplateApiEndpoint + '/entityType/' + entityTypeItemId + '/template-linked-entity-configuration');
    }

    createFormRegistrationTemplate(model: FormRegistrationTemplateTreeNodeDTO): Observable<FormRegistrationTemplateTreeNodeDTO> {
        return this.http.post(this.formRegistrationTemplateApiEndpoint + '/structure', model);
    }

    updateFormRegistrationTemplate(model: FormRegistrationTemplateDTO): Observable<FormRegistrationTemplateDTO> {
        return this.http.put(this.formRegistrationTemplateApiEndpoint + '/' + model.formRegistrationTemplateId + '/structure', model);
    }

    createActivateFormRegistrationTemplate(model: FormRegistrationTemplateTreeNodeDTO): Observable<FormRegistrationTemplateTreeNodeDTO> {
        const params = {
            action: 'activate'
        };
        return this.http.post(this.formRegistrationTemplateApiEndpoint + '/structure', model, params);
    }

    updateActivateFormRegistrationTemplate(model: FormRegistrationTemplateTreeNodeDTO): Observable<FormRegistrationTemplateTreeNodeDTO> {
        const params = {
            action: 'activate'
        };
        return this.http.put(this.formRegistrationTemplateApiEndpoint + '/' + model.formRegistrationTemplateId + '/structure', model, params);
    }

    createDeactivateFormRegistrationTemplate(model: FormRegistrationTemplateTreeNodeDTO): Observable<FormRegistrationTemplateTreeNodeDTO> {
        const params = {
            action: 'deactivate'
        };
        return this.http.post(this.formRegistrationTemplateApiEndpoint + '/structure', model, params);
    }

    updateDeactivateFormRegistrationTemplate(model: FormRegistrationTemplateTreeNodeDTO): Observable<FormRegistrationTemplateTreeNodeDTO> {
        const params = {
            action: 'deactivate'
        };
        return this.http.put(this.formRegistrationTemplateApiEndpoint + '/' + model.formRegistrationTemplateId + '/structure', model, params);
    }

    deleteFormRegistrationTemplate(formRegistrationTemplateId: number): Observable<FormRegistrationTemplateDTO> {
        return this.http.delete(this.formRegistrationTemplateApiEndpoint + '/' + formRegistrationTemplateId);
    }

    fetchCriteriaList(entityTypeCode: string, componentTypeCode: string, fieldAnswerTypeCode: string): Observable<FormRegistrationCriteriaListItemDTO[]> {
        return this.http.get(
            this.formRegistrationTemplateApiEndpoint + '/criteria',
            {
                templateTypeCode: entityTypeCode,
                componentTypeCode: componentTypeCode,
                fieldAnswerTypeCode: fieldAnswerTypeCode
            });
    }

    fetchTemplateFieldDataTypeList(entityItemCode: string): Observable<ConfigurationFormRegistrationTemplateFieldDataTypeListItemDTO[]> {
        return this.http.get(
            this.formRegistrationTemplateApiEndpoint + '/configuration-template-field-data-type',
            {
                entityItemCode: entityItemCode
            });
    }

}
