<label *ngIf="label && label.length > 0" attr.for="{{name}}">{{label}}</label>
<div>
    <div class="horizontal">
        <kendo-combobox #pickerCombobox
                        [data]="listItems"
                        [textField]="'name'"
                        [valueField]="'id'"
                        [placeholder]="_placeholder"
                        [filterable]="true"
                        [(ngModel)]="simpleValue"
                        (valueChange)="valueChangeHandler($event)"
                        (cancel)="cancelHandler()"
                        [disabled]="disabled"
                        class="no-arrow fiba-picker">

            <ng-template kendoComboBoxItemTemplate let-nf>
                <span>{{nf.name}} <i *ngIf="nf.isActive === false" class="fa fa-lg fa-warning" style="color:orangered"></i></span>
            </ng-template>

        </kendo-combobox>
        <button kendoButton type="button"
                (click)="editHandler()"
                class="icon-only"
                [disabled]="disabled">
            <i class="fa fa-lg fa-search"></i>
        </button>

        <div *ngIf="simpleValue && simpleValue.isActive === false" style="margin-left: 5px; padding-top: 3px;">
            <i class="fa fa-lg fa-warning" style="color:orangered"></i>
        </div>

    </div>
    <span *ngIf="displayNewTag && value && value.clubStatus && value.clubStatus.code == OrganisationStatusCodes_Temporary"
          style="color: darkorange; font-weight: bold;">
        NEW
    </span>

    <div *ngIf="ngControl.errors" class="validation-errors">
        <ul>
            <li [hidden]="!ngControl.errors.required">
                {{label}} is required
            </li>
        </ul>
    </div>
</div>
