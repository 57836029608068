import { HostListener, OnDestroy, OnInit, ViewChild, Directive } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { Observable, Subject, Subscription } from 'rxjs';

import { FibaLoadingService } from '@fiba/loading';
import { DialogActionService, DialogCloseResult } from '@fiba/utils/dialog-action.service';
import { NotificationService, NotificationType } from '@fiba/utils/notification.service';
import { PromptBeforeLeaving } from '@fiba/utils/prompt-before-leaving.service';
import { BaseComponent } from '@app/components/helper/destroyable';

@Directive()
export abstract class FibaFormBase<T> extends BaseComponent implements PromptBeforeLeaving, OnInit, OnDestroy {
    public isNew: boolean;
    public isLoading: boolean;
    public model: T;

    public readonly loadingThreshold:number = 800;
    public readonly loadingText = 'Loading';
    public readonly savingThreshold = 0;
    public readonly savingText = 'Saving';
    public sub: Subscription = new Subscription();

    protected description: string;
    entityId: number; // PK of the edited entity
    formSaveSuccessEvent: Subject<boolean> = new Subject<boolean>();

    @ViewChild('form') ngForm: NgForm;

    constructor(
        protected route: ActivatedRoute,
        protected dialogActionService: DialogActionService,
        protected notificationService: NotificationService,
        protected fibaLoadingService: FibaLoadingService) {
        super();
    }

    @HostListener('window:beforeunload')
    public canLeave(): boolean | Promise<boolean> {
        // TODO - 02/05/2018 - Is fn Usued ? - ??? 22/08/2018
        const fn = (result: any) => {
            if (!(result instanceof DialogCloseResult)) {
                if (result.text === DialogActionService.ANSWER_OK) {
                    return true;

                }
            }
            return false;
        };

        if (!this.ngForm || this.ngForm.pristine) {
            return true;
        } else {
            return this.dialogActionService.askCanLeave();
        }
    }

    ngOnDestroy(): never {
        this.sub.unsubscribe();
        super.ngOnDestroy();
    }

    protected getDescription(): string {
        return this.description ? this.description : this.model.constructor.name;
    }

    // FETCH
    protected abstract getFetchObservable(): Observable<T>;

    /**
     * Called when fetch succeeds. Triggers filling the model from the API data.
     *
     * @param response
     */
    protected fetchSuccess(response): void {
        this.model = response;
    }

    /**
     * Called when fetch API returns an error.
     *
     * @param error
     */
    protected fetchError(error): void {
        this.fibaLoadingService.hide();
        this.notificationService.emitNotification(NotificationType.Error, error);
    }

    /**
     * Called when fetch is complete (after fetchSuccess).
     */
    protected fetchComplete(): void {
        this.isLoading = false;
        this.fibaLoadingService.hide();
        if (!this.model || (typeof (this.model['hasWriteAccess']) === 'boolean')) {
            // TODO: Ugly hack to be reworked (maybe with Guards? https://angular.io/docs/ts/latest/guide/router.html#!#guards)
            setTimeout(() => {
                if (this.ngForm) {
                    if (!this.model['hasWriteAccess']) {
                        for (const key in this.ngForm.form.controls) {
                            this.ngForm.form.controls[key].disable();
                        }
                    //} else if (this.model['hasWriteAccess']) {
                    //    for (const key in this.ngForm.form.controls) {
                    //        this.ngForm.form.controls[key].enable();
                        }
                    }
                //}
            }, 15);
        }
    }

    // CREATE
    protected getCreateObservable(): Observable<T> {
        return null;
    }

    /**
     * Called when creation succeeds.
     *
     * @param model
     */
    protected createSuccess(model: T): void {
        this.model = model;
    }

    /**
     * Called when create API returns an error.
     *
     * @param error
     */
    protected createError(error) {
        if (Array.isArray(error)) { // If array, it's a list of validation errors. Maybe TODO refactor properly with an error type instead
            this.notificationService.emitNotification(NotificationType.Warning,
                `Validation failed on ${this.getDescription()} creation : ${error}`);
        } else {
            this.notificationService.emitNotification(NotificationType.Error,
                `Create ${this.getDescription()} failed: ${error}`);
        }
        this.fibaLoadingService.hide();
        this.isLoading = false;
    }

    /**
     * Called when create is complete (after createSuccess). Triggers redirect.
     */
    protected createComplete() {
        // Mark all inputs pristine & untouched
        for (const key in this.ngForm.form.controls) {
            this.ngForm.form.controls[key].markAsPristine();
            this.ngForm.form.controls[key].markAsUntouched();
        }
        this.notificationService.emitNotification(NotificationType.Success, `Created new ${this.getDescription()} successfully`);
        this.redirectAfterCreate();
    }

    protected redirectAfterCreate(): void { }

    // UPDATE
    protected abstract getUpdateObservable(): Observable<any>;

    /**
     * Called when update succeeds.
     *
     * @param response the response of the update API. Should contain the DTO of the created entity.
     */
    protected updateSuccess(response): void {
    }

    /**
     * Called when update API returns an error.
     *
     * @param error
     */
    protected updateError(error) {
        if (Array.isArray(error)) { // If array, it's a list of validation errors. Maybe TODO refactor properly with an error type instead
            this.notificationService.emitNotification(NotificationType.Warning,
                `Validation failed on ${this.getDescription()} update : ${error}`);
        } else {
            this.notificationService.emitNotification(NotificationType.Error,
                `Update ${this.getDescription()} failed: ${error}`);
        }
        this.fibaLoadingService.hide();
        this.isLoading = false;
    }

    /**
     * Called when update is complete.
     */
    protected updateComplete() {
        this.notificationService.emitNotification(NotificationType.Success, `Updated ${this.getDescription()} ${this.entityId} successfully`);
        this.isLoading = false;
        // Mark all inputs pristine & untouched
        for (const key in this.ngForm.form.controls) {
            this.ngForm.form.controls[key].markAsPristine();
            this.ngForm.form.controls[key].markAsUntouched();
        }
        this.loadItem();
    }

    public ngOnInit(): void {
        this.isLoading = true;
        this.loadItem();
    }

    protected retrieveRouteParams(route: ActivatedRoute): Observable<Params> {
        return route.parent.params;
    }

    protected retrieveEntityId(routeParams: Params): number {
        return routeParams.id;
    }

    public loadItem(): void {
        this.fibaLoadingService.show(this.loadingThreshold, this.loadingText);
        this.sub.add(this.retrieveRouteParams(this.route).subscribe(
            (params) => {
                this.entityId = this.retrieveEntityId(params);
                if (this.canFetchItem()) { // Update
                    this.isNew = false;
                    this.sub.add( this.getFetchObservable().subscribe(
                        (response) => {
                            this.fetchSuccess(response);
                        },
                        (error) => {
                            this.fetchError(error);
                        },
                        () => {
                            this.fetchComplete();
                            this.sub.add(this.formSaveSuccessEvent.next(true));
                        },
                    ));
                } else { // Create
                    this.isNew = true;
                    this.model = this.initNewModel();
                    this.fibaLoadingService.hide();
                    this.isLoading = false;
                }
            },
        ));
    }

    public canFetchItem(): boolean {
        return this.entityId !== undefined;
    }

    public isEntityUpdate(): boolean {
        return this.entityId !== undefined;
    }


    protected initNewModel(): T {
        return null;
    }

    protected save(): boolean {
        // Mark all inputs touched
        for (const key in this.ngForm.form.controls) {
            this.ngForm.form.controls[key].markAsTouched();
        }

        let errors = [];
        for (const name in this.ngForm.controls) {
            if (this.ngForm.controls[name].invalid) {
                errors.push(name);
            }
        }

        if (!this.ngForm.valid && errors.length > 0) {
            this.notificationService.emitNotification(NotificationType.Warning, 'There are validation errors on fields : ' + errors.join(', '));
            return false;
        } else {
            this.sub.unsubscribe()
            this.sub = new Subscription();

            this.fibaLoadingService.show(this.savingThreshold, this.savingText);
            this.isLoading = true;
            if (this.isEntityUpdate()) { // update
                this.sub.add(this.getUpdateObservable().subscribe(
                    (response) => {
                        this.updateSuccess(response);
                    },
                    (error) => {
                        this.updateError(error);
                    },
                    () => {
                        this.fibaLoadingService.hide();
                        this.isLoading = false;
                        this.updateComplete();
                    },
                ));
            } else { // create
                this.sub.add(this.getCreateObservable().subscribe(
                    (response) => {
                        this.createSuccess(response);
                    },
                    (error) => {
                        this.createError(error);
                    },
                    () => {
                        this.fibaLoadingService.hide();
                        this.isLoading = false;
                        this.createComplete();
                    },
                ));
            }

            return true;
        }
    }

    /** 
     * Called when user clicks on the close button
     */
    abstract close(): void;


    //public findInvalidControlsRecursive(formToInvestigate: FormGroup | FormArray): string[] {
    //    var invalidControls: string[] = [];
    //    let recursiveFunc = (form: FormGroup | FormArray) => {
    //        Object.keys(form.controls).forEach(field => {
    //            const control = form.get(field);
    //            if (control.invalid) invalidControls.push(field);
    //            if (control instanceof FormGroup) {
    //                recursiveFunc(control);
    //            } else if (control instanceof FormArray) {
    //                recursiveFunc(control);
    //            }
    //        });
    //    }
    //    recursiveFunc(formToInvestigate);
    //    return invalidControls;
    //}
}
