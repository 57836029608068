import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MapPolicy } from '@fiba/models';
import { AuthGuard, AuthGuardNonSpecialCases, AuthGuardWithToken } from '@fiba/data-services';

import { AuthHandlerSilentComponent } from './components/auth-handler-silent.component';
import { AuthHandlerComponent } from './components/auth-handler.component';
import { ChangePasswordComponent } from './components/change-password.component';
import { DummyRoutingComponent } from './components/dummy-routing.component';
import { RootComponent } from './components/root.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions.component';

const routes: Routes = [
    {
        path: '',
        component: RootComponent,
        canActivate: [AuthGuard],
        data: { breadcrumb: 'Home' },
        canActivateChild: [AuthGuard],
        children: [
            {
                path: '',
                redirectTo: 'home',
                pathMatch: 'full',
            },
            {
                path: 'home',
                data: { breadcrumb: 'Home', hiddenInBreadcrumb: true },
                loadChildren: () => import('./components/home/home.module').then(m => m.HomeModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'td-portal-dashboard',
                data: { breadcrumb: 'Dashboard', policy: MapPolicy.TechnicalMeetingOperatorPortal },
                loadChildren: () => import('../@fiba/td-portal-dashboard/td-portal-dashboard.module').then(m => m.TdPortalDashboardModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'persons',
                loadChildren: () => import('../@fiba/person/person.module').then(m => m.PersonModule),
                data: { breadcrumb: 'Persons', policy: MapPolicy.PersonsBrowse },
                canActivate: [AuthGuard],
            },
            {
                path: 'players',
                loadChildren: () => import('../@fiba/person/player.module').then(m => m.PlayerModule),
                canActivate: [AuthGuard],
                data: { breadcrumb: 'Players', policy: MapPolicy.PlayersBrowse },
            },
            {
                path: 'wabc-members',
                data: { breadcrumb: 'WABC Members', policy: MapPolicy.WabcBrowse },
                loadChildren: () => import('../@fiba/wabc/wabc-member.module').then(m => m.WabcMemberModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'wabc-courses',
                data: { breadcrumb: 'WABC Courses', policy: MapPolicy.WabcBrowse },
                loadChildren: () => import('../@fiba/wabc/wabc-course.module').then(m => m.WabcCourseModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'coaches',
                data: { breadcrumb: 'Coaches', policy: MapPolicy.CoachesBrowse },
                loadChildren: () => import('../@fiba/person/coach.module').then(m => m.CoachModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'agents',
                data: { breadcrumb: 'Agents', policy: MapPolicy.AgentsBrowse },
                loadChildren: () => import('../@fiba/agent/agent.module').then(m => m.AgentModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'technical-delegates',
                data: { breadcrumb: 'Technical delegates', policy: MapPolicy.OfficialRegistrationsBrowse },
                loadChildren: () => import('../@fiba/technical-delegate/technical-delegate.module').then(m => m.TechnicalDelegateModule),
                canActivate: [AuthGuard],
            },
            //{
            //    path: 'agent-user-contracts',
            //    data: { breadcrumb: 'My contracts', policy: MapPolicy.AgentRelationsContractsBrowse },
            //    loadChildren: () => import('../@fiba/agent/agent-user.module').then(m => m.AgentUserModule),
            //    canActivate: [AuthGuard],
            //},
            {
                path: 'agent-user-informations',
                data: { breadcrumb: 'My information', policy: MapPolicy.AgentRelationsContractsBrowse },
                loadChildren: () => import('../@fiba/agent/agent-information-form/agent-information-form.module').then(m => m.AgentInformationFormModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'game-official-user-informations',
                data: { breadcrumb: 'My information', policy: MapPolicy.GameOfficialsElicensePortalBrowse },
                loadChildren: () => import('../@fiba/game-official/game-official-information-form/game-official-information-form.module').then(m => m.GameOfficialInformationFormModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'game-official-my-e-license',
                data: { breadcrumb: 'My e-license', policy: MapPolicy.GameOfficialsElicensePortalMyELicenseBrowse },
                loadChildren: () => import('../@fiba/game-official/game-official-my-e-license-form/game-official-my-e-license-form.module').then(m => m.GameOfficialMyELicenseFormModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'users',
                data: { breadcrumb: 'Users', policy: MapPolicy.UsersBrowse },
                loadChildren: () => import('../@fiba/user/user.module').then(m => m.UserModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'organisations',
                data: { breadcrumb: 'Organisations', policy: MapPolicy.OrganisationsBrowse },
                loadChildren: () => import('../@fiba/organisation/organisation.module').then(m => m.OrganisationModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'nationalfederations',
                data: { breadcrumb: 'NF', policy: MapPolicy.NationalFederationsBrowse },
                loadChildren: () => import('../@fiba/organisation/national-federation.module').then(m => m.NationalFederationModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'clubs',
                data: { breadcrumb: 'Clubs', policy: MapPolicy.ClubsBrowse },
                loadChildren: () => import('../@fiba/organisation/club.module').then(m => m.ClubModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'fiba-partner-organisation',
                data: { breadcrumb: 'fiba-partner', policy: MapPolicy.EVCRead },
                loadChildren: () => import('../@fiba/organisation/fiba-partner.module').then(m => m.FibaPartnerOrganisationModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'sanction-applications',
                data: { breadcrumb: 'Sanction applications', policy: MapPolicy.SanctionsBrowse },
                loadChildren: () => import('../@fiba/sanction-application/sanction-application.module').then(m => m.SanctionApplicationModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'player-licenses',
                data: { breadcrumb: 'Player Licenses', policy: MapPolicy.PlayerLicensesBrowse },
                loadChildren: () => import('../@fiba/player-license/player-license.module').then(m => m.FibaPlayerLicenseModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'coach-licenses',
                data: { breadcrumb: 'Coach Licenses', policy: MapPolicy.CoachLicensesBrowse },
                loadChildren: () => import('../@fiba/coach-license/coach-license.module').then(m => m.CoachLicenseModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'coach-license-requests',
                data: { breadcrumb: 'Coach License Requests', policy: MapPolicy.CoachLicensesBrowse },
                loadChildren: () => import('../@fiba/coach-license/coach-license-requests.module').then(m => m.CoachLicenseRequestsModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'official-licenses',
                data: { breadcrumb: 'Official Licenses', policy: MapPolicy.OfficialLicensesBrowse },
                loadChildren: () => import('../@fiba/official-licenses/official-licenses.module').then(m => m.OfficialLicensesModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'transfers-dashboard',
                data: { breadcrumb: 'Transfer Requests', policy: MapPolicy.TransferRequestsDashboardBrowse },
                loadChildren: () => import('../@fiba/transfer/transfer-dashboard/transfer-dashboard.module').then(m => m.TransferDashboardModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'transfer-requests',
                data: { breadcrumb: 'Transfer Requests', policy: MapPolicy.TransferRequestsBrowse },
                loadChildren: () => import('../@fiba/transfer-request/transfer-request.module').then(m => m.TransferRequestModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'insurance-policies',
                data: { breadcrumb: 'Insurance policy', policy: MapPolicy.InsurancePoliciesBrowse },
                loadChildren: () => import('../@fiba/insurance-policy/insurance-policy.module').then(m => m.InsurancePolicyModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'competitions',
                data: { breadcrumb: 'Competitions', policy: MapPolicy.CompetitionsBrowse },
                loadChildren: () => import('../@fiba/competition/competition.module').then(m => m.CompetitionModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'games',
                data: { breadcrumb: 'Games', policy: MapPolicy.CompetitionsOperationRead },
                loadChildren: () => import('../@fiba/game/game.module').then(m => m.GameModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'events',
                data: { breadcrumb: 'Events', policy: MapPolicy.EventsBrowse },
                loadChildren: () => import('../@fiba/event/event.module').then(m => m.EventModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'form-registration-templates',
                data: { breadcrumb: 'Form registrations', policy: MapPolicy.FormRegistrationTemplateBrowse },
                loadChildren: () => import('../@fiba/form-registration/form-registration.module').then(m => m.FormRegistrationModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'venue-form-registrations',
                data: { breadcrumb: 'Venue form registrations', policy: MapPolicy.FormRegistrationVenueBrowse },
                loadChildren: () => import('../@fiba/form-registration-venue/form-registration-venue.module').then(m => m.FormRegistrationVenueModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'financial-packages',
                data: { breadcrumb: 'Financial Packages', policy: MapPolicy.FinancialReportingBrowse },
                loadChildren: () => import('../@fiba/financial-reporting/financial-reporting.module').then(m => m.FinancialReportingModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'payments-admin',
                data: { breadcrumb: 'Payments', policy: MapPolicy.PaymentsBrowse },
                loadChildren: () => import('../@fiba/payment/payment-admin.module').then(m => m.PaymentAdminModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'payments',
                data: { breadcrumb: 'Payments', policy: MapPolicy.PaymentsRead },
                loadChildren: () => import('../@fiba/payment/payment.module').then(m => m.PaymentModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'venues',
                data: { breadcrumb: 'Venues', policy: MapPolicy.VenuesBrowse },
                loadChildren: () => import('../@fiba/competition/venue/venue.module').then(m => m.VenueModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'site-visit',
                data: { breadcrumb: 'Site Visit', policy: MapPolicy.FormRegistrationSiteVisitBrowse },
                loadChildren: () => import('../@fiba/site-visit/site-visit.module').then(m => m.SiteVisitModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'merge',
                data: { breadcrumb: 'Merge', policy: MapPolicy.MergeBrowse },
                loadChildren: () => import('../@fiba/merge/merge.module').then(m => m.MergeModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'administration',
                data: { breadcrumb: 'Administration', policy: MapPolicy.AdministrationBrowse },
                loadChildren: () => import('../@fiba/administration/administration.module').then(m => m.AdministrationModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'standing-headers',
                data: { breadcrumb: 'Standing Headers', policy: MapPolicy.StandingHeadersBrowse },
                loadChildren: () => import('../@fiba/standing-headers/standing-headers.module').then(m => m.StandingHeadersModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'ranking',
                loadChildren: () => import('../@fiba/ranking/ranking.module').then(m => m.RankingModule),
                data: { breadcrumb: 'Ranking', policy: MapPolicy.RankingBrowse },
                canActivate: [AuthGuard],
            },
            {
                path: 'reports',
                loadChildren: () => import('../@fiba/reporting/reporting.module').then(m => m.ReportingModule),
                data: { breadcrumb: 'Reports', policy: MapPolicy.ReportsBrowse },
                canActivate: [AuthGuard],
            },
            {
                path: 'persons-view',
                loadChildren: () => import('../@fiba/person-view/person-view.module').then(m => m.PersonViewModule),
                data: { breadcrumb: 'Persons', policy: MapPolicy.PersonsRead },
                canActivate: [AuthGuard],
            },
            {
                path: 'player-release',
                loadChildren: () => import('../@fiba/player-release/player-release.module').then(m => m.PlayerReleaseModule),
                data: { breadcrumb: 'Player release', policy: MapPolicy.PlayerReleaseRead },
                canActivate: [AuthGuard],
            },
            {
                path: 'coaches-view',
                data: { breadcrumb: 'Coaches', policy: MapPolicy.CoachesRead },
                loadChildren: () => import('../@fiba/person/coach-view/coach-view.module').then(m => m.CoachViewModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'user-profile',
                data: { breadcrumb: 'User Profile'},
                loadChildren: () => import('../@fiba/user-profile/user-profile.module').then(m => m.UserProfileModule),
                canActivate: [AuthGuard],
            },
            {
                data: { breadcrumb: 'WEB CIS', policy: MapPolicy.WebCIS },
                path: 'web-cis',
                loadChildren: () => import('../@fiba/web-cis/web-cis.module').then(m => m.WebCisModule),
                canActivate: [AuthGuard],
            },
            //{
            //    data: { breadcrumb: 'LSI', policy: MapPolicy.Lsi },
            //    path: 'lsi',
            //    loadChildren: () => import('../@fiba/lsi/lsi.module').then(m => m.LsiModule),
            //    canActivate: [AuthGuard],
            //},
            {
                path: 'equipment-configuration',
                data: { breadcrumb: 'E&VC Configuration', policy: MapPolicy.EVCRead },
                loadChildren: () => import('../@fiba/equipment/equipment-configuration/equipment-configuration.module').then(m => m.EquipmentConfigurationModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'equipment-fiba-partners',
                data: { breadcrumb: 'E&VC Partners', policy: MapPolicy.EVCRead },
                loadChildren: () => import('../@fiba/equipment/fiba-partner/fiba-partner.module').then(m => m.FibaPartnerModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'equipment-test-institutes',
                data: { breadcrumb: 'Test Institute', policy: MapPolicy.EVCRead },
                loadChildren: () => import('../@fiba/organisation/test-institute.module').then(m => m.TestInstituteModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'test-report',
                data: { breadcrumb: 'Products', policy: MapPolicy.TestReportBrowse},
                loadChildren: () => import('../@fiba/equipment/equipment-product/equipment-product.module').then(m => m.EquipmentProductModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'equipment-products',
                data: { breadcrumb: 'Products', policy: MapPolicy.FormRegistrationEquipmentTestResultEdit, onPartnerView: false },
                loadChildren: () => import('../@fiba/equipment/equipment-product/equipment-product.module').then(m => m.EquipmentProductModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'equipment-products/test-result-form',
                data: { breadcrumb: 'Products', policy: MapPolicy.FormRegistrationEquipmentTestResultEdit, onPartnerView: false },
                loadChildren: () => import('../@fiba/equipment/test-result-application/test-result-application.module').then(m => m.TestResultApplicationModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'equipment-agreements',
                data: { breadcrumb: 'Agreement', policy: MapPolicy.EVCRead },
                loadChildren: () => import('../@fiba/equipment/agreement/agreement.module').then(m => m.AgreementModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'equipment-product-applications',
                data: { breadcrumb: 'Product Application', policy: MapPolicy.EVCRead },
                loadChildren: () => import('../@fiba/equipment/product-application/product-application.module').then(m => m.ProductApplicationModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'equipment-partner-applications',
                data: { breadcrumb: 'Applicants', policy: MapPolicy.EVCRead },
                loadChildren: () => import('../@fiba/equipment/partner-application/partner-application.module').then(m => m.PartnerApplicationModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'equipment-dashboard',
                data: { breadcrumb: 'Dashboard', policy: MapPolicy.EVCRead },
                loadChildren: () => import('../@fiba/equipment/management-dashboard/management-dashboard.module').then(m => m.ManagementDashboardModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'equipment-agreements',
                data: { breadcrumb: 'Agreement', policy: MapPolicy.EVCRead },
                loadChildren: () => import('../@fiba/equipment/agreement/agreement-routing.module').then(m => m.AgreementRoutingModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'equipment-payments',
                data: { breadcrumb: 'Payments', policy: MapPolicy.EVCRead },
                loadChildren: () => import('../@fiba/equipment/payment/payment.module').then(m => m.PaymentModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'partner-equipment-product-applications',
                data: { breadcrumb: 'Product Application', policy: MapPolicy.EquipmentPartnerBrowse },
                loadChildren: () => import('../@fiba/equipment/partner-product-application/partner-product-application.module').then(m => m.PartnerProductApplicationModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'partner-equipment-products',
                data: { breadcrumb: 'Equipment', policy: MapPolicy.EquipmentPartnerBrowse, onPartnerView: true },
                loadChildren: () => import('../@fiba/equipment/equipment-product/equipment-product.module').then(m => m.EquipmentProductModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'partner-equipment-agreements',
                data: { breadcrumb: 'Agreement', policy: MapPolicy.EquipmentPartnerBrowse, onPartnerView: true },
                loadChildren: () => import('../@fiba/equipment/agreement/agreement.module').then(m => m.AgreementModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'partner-equipment-profile',
                data: { breadcrumb: 'Profile', policy: MapPolicy.EquipmentPartnerBrowse },
                loadChildren: () => import('../@fiba/equipment/partner-profile/partner-profile.module').then(m => m.PartnerProfileModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'partner-equipment-payments',
                data: { breadcrumb: 'Payments', policy: MapPolicy.EquipmentPartnerBrowse },
                loadChildren: () => import('../@fiba/equipment/payment/payment.module').then(m => m.PaymentModule),
                canActivate: [AuthGuard],
            },
        ],
    },
    {
        path: 'auth-handler',
        component: AuthHandlerComponent,
    },
    {
        path: 'auth-handler-silent',
        component: AuthHandlerSilentComponent,
    },
    {
        path: 'change-password',
        component: ChangePasswordComponent,
        canActivate: [AuthGuardNonSpecialCases],
        data: { breadcrumb: 'Change User Password' },
    },
    {
        path: 'terms-and-conditions',
        component: TermsAndConditionsComponent,
        canActivate: [AuthGuardNonSpecialCases],
        data: { breadcrumb: 'General Terms and Conditions of Use' },
    },
    {
        path: 'self-reset-token',
        canActivate: [AuthGuardWithToken],
        component: DummyRoutingComponent,
    },
    { path: '**', redirectTo: '' },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { enableTracing: false }), /* while debugging, set this to 'true' to enable extensive information about route */
    ],
    exports: [
        RouterModule,
    ],
})
export class AppRoutingModule {
}
