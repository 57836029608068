import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiListResponse, NationalFederationDetailsDTO, NationalFederationDTO, NationalFederationFilters, NationalFederationListItemDTO, NationalFederationMembershipStatusDTO, ThingDTO, ZoneDTO } from '@fiba/models';

import { DataCachedService } from '../utils/data-cached.service';
import { HttpService, IFileData } from '../utils/http.service';
import { NationalFederationGroupedByZoneDTO } from '@fiba/models/dtos/generated/national-federation-grouped-by-zone';
import {AppConfig} from '@app/app.config';
import { ThingWithStateDTO } from '../../models/dtos/generated/thing-with-state-dto';

@Injectable({
    providedIn: 'root',
})
export class NationalFederationDataService {
    protected nationalFederationApiEndpoint: string;
    protected nationalFederationAutocompleteApiEndpoint: string;
    protected nationalFederationMembershipStatusApiEndpoint: string;

    constructor(
        protected http: HttpService,
        protected config: AppConfig,
        protected dataNationalFederationStatusService: DataCachedService<NationalFederationMembershipStatusDTO>) {
        this.nationalFederationApiEndpoint = config.nationalFederationApiEndpoint;
        this.nationalFederationAutocompleteApiEndpoint = config.nationalFederationAutocompleteApiEndpoint;
        this.nationalFederationMembershipStatusApiEndpoint = config.nationalFederationMembershipStatusApiEndpoint;
    }

    public fetchList(filters: NationalFederationFilters): Observable<ApiListResponse<NationalFederationListItemDTO>> {
        return this.http.get(this.nationalFederationApiEndpoint, filters.getSearchParams());
    }

    public fetchNationalFederationForClub(clubId: number): Observable<NationalFederationDTO> {
        return this.http.get(`${this.nationalFederationApiEndpoint}/club/${clubId}`);
    }

    public fetchNationalFederation(organisationId: number): Observable<NationalFederationDTO> {
        return this.http.get(`${this.nationalFederationApiEndpoint}/${organisationId}`);
    }

    public fetchNationalFederationDetails(organisationId: number): Observable<NationalFederationDetailsDTO> {
        return this.http.get(`${this.nationalFederationApiEndpoint}/${organisationId}/details`);
    }

    public fetchStatuses(): Observable<NationalFederationMembershipStatusDTO[]> {
        return this.dataNationalFederationStatusService.fetchEntities(this.nationalFederationMembershipStatusApiEndpoint);
    }

    public fetchAutoCompleteList(name: string = '', zone: ZoneDTO = null): Observable<ThingWithStateDTO[]> {
        const params = { name };
        if (zone) {
            params['zoneId'] = String(zone.zoneId);
        }
        return this.http.get(this.nationalFederationAutocompleteApiEndpoint, params);
    }

    public fetchTreeviewCompleteList(zones: ZoneDTO[] = null): Observable<NationalFederationGroupedByZoneDTO[]> {
        const params = {};

        if (zones && zones.length > 0) {
            const ids = zones.map((zone) => zone.zoneId);
            params['zoneIds'] = ids.toString();
        }

        return this.http.get(`${this.nationalFederationApiEndpoint}/treeview`, params);
    }

    public updateNationalFederationDetails(model: NationalFederationDetailsDTO): Observable<any> {
        return this.http.put(`${this.nationalFederationApiEndpoint}/${model.organisationId}/details`, model);
    }

    public downloadExport(filters: NationalFederationFilters): Observable<IFileData> {
        return this.http.getFileData(`${this.nationalFederationApiEndpoint}/export-csv`, filters.getSearchParams());
    }
}
