import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
    ApiListResponse,
    CoachLicenseDTO,
    ThingDTO
} from '@fiba/models';

import { HttpService } from '../utils/http.service';
import { AppConfig } from '@app/app.config';
import { WabcMemberFilters } from '../../models/wabc/wabc-member-filters';
import { WabcMemberListItemDTO } from '../../models/dtos/generated/wabc-member-list-item-dto';
import { WabcMemberDTO } from '../../models/dtos/generated/wabc-member-dto';
import { WabcCourseCandidateDTO } from '../../models/dtos/generated/wabc-course-candidate-dto';
import { WabcMemberStatusDTO } from '../../models/dtos/generated/wabc-member-status-dto';

@Injectable({
    providedIn: 'root',
})
export class WabcMemberDataService {

    protected baseUrlEndpoint: string;
    protected wabcMemberApiEndpoint: string;

    constructor(
        protected http: HttpService,
        protected config: AppConfig) {
        this.baseUrlEndpoint = config.baseUrl;
        this.wabcMemberApiEndpoint = config.wabcMemberApiEndpoint;
    }

    public fetchList(filters: WabcMemberFilters, context: string = null): Observable<ApiListResponse<WabcMemberListItemDTO>> {
        return this.http.get(this.wabcMemberApiEndpoint, filters.getSearchParams());
    }

    public fetchAutoCompleteList(name: string): Observable<ThingDTO[]> {
        return this.http.get(this.wabcMemberApiEndpoint + '/autocomplete/' + name);
    }

    public fetchWabcMember(wabcMemberId: number): Observable<WabcMemberDTO> {
        return this.http.get(this.wabcMemberApiEndpoint + '/' + wabcMemberId);
    }

    public createWabcMember(wabcMemberDto: WabcMemberDTO): Observable<WabcMemberDTO> {
        return this.http.post(this.wabcMemberApiEndpoint, wabcMemberDto);
    }

    public updateWabcMember(wabcMemberId: number, wabcMemberDto: WabcMemberDTO): Observable<WabcMemberDTO> {
        return this.http.put(this.wabcMemberApiEndpoint + '/' + wabcMemberId, wabcMemberDto);
    }

    public deleteWabcMember(wabcMemberId: number): Observable<WabcMemberDTO> {
        return this.http.delete(this.wabcMemberApiEndpoint + '/' + wabcMemberId);
    }

    public fetchWabcMemberEducation(wabcMemberId: number): Observable<WabcCourseCandidateDTO[]> {
        return this.http.get(this.wabcMemberApiEndpoint + '/' + wabcMemberId + '/education');
    }

    public updateStatusWabcMember(wabcMemberId: number, wabcMemberDto: WabcMemberDTO, statusCode: string): Observable<WabcMemberDTO> {
        return this.http.put(this.wabcMemberApiEndpoint + '/' + wabcMemberId + '/status/' + statusCode, wabcMemberDto);
    }

    public fetchStatuses(): Observable<WabcMemberStatusDTO[]> {
        return this.http.get(this.wabcMemberApiEndpoint + '/statuses');
    }

    public createManualLicense(wabcMemberId: number, licenseDto: CoachLicenseDTO): Observable<CoachLicenseDTO[]> {
        return this.http.post(this.wabcMemberApiEndpoint + '/' + wabcMemberId + '/create-license', licenseDto);
    }

}
