import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { ClubDTO, ClubListItemDTO, OrganisationDTO, ZoneDTO, City, ItemCategoryCodes, OrganisationStatusCodes } from '@fiba/models';
import { SelectionEvent } from '@progress/kendo-angular-grid';
import { ClubSearchBaseComponent } from '../../../base/club-search-base.component';

import { ClubDataService } from '@fiba/data-services';
import { NotificationService } from '@fiba/utils/notification.service';
import { SimpleStorageService } from '@fiba/utils/simple-storage.service';

const CLUB_MINI_SEARCH_PAGE_SIZE = 15;

@Component({
    selector: 'club-search-mini',
    templateUrl: './club-search-mini.component.html',
    styleUrls: ['./club-search-mini.component.css']
})
export class ClubSearchMiniComponent extends ClubSearchBaseComponent implements OnInit {

    public readonly ItemCategoryCodes_ClubGender = ItemCategoryCodes.ClubGender;
    public readonly OrganisationStatusCodes_Inactive = OrganisationStatusCodes.Inactive;

    public clubCreated: ClubDTO = null;
    public nationalFederationCountryId: number = null;
    public selectedCity: City = null;
    public initialSearchDone = false;

    @Input() public contextCode: string;
    @Input() public showClubsForAllNf = false;
    @Input() public mergeMode: boolean = false;
    @Input() public teamDelegationMode: boolean = false;

    @Input()
    protected set organisation(value: OrganisationDTO) {
        if (value) {
            this.nationalFederationCountryId = value.countryId;
            this.filters.parentOrganisation.setValue(value);
        } else {
            this.filters.parentOrganisation.reset();
        }
    }

    @Input()
    public set zone(value: ZoneDTO) {
        if (value) {
            this.filters.zone.setFilterValue([value]);
        } else {
            this.filters.zone.reset();
        }
    }

    @Input()
    protected set licenseCategoryCode(value: string) {
        if (value) {
            this.filters.licenseCategoryCode = value;
        } else {
            this.filters.licenseCategoryCode = null;
        }
    }

    @Input()
    protected set season(value: number) {
        if (value) {
            this.filters.season = String(value);
        } else {
            this.filters.season = null;
        }
    }

    @Input()
    public set genderCode(value: string) {
        if (value) {
            this.filters.clubGenderCode = value;
        } else {
            this.filters.clubGenderCode = null;
        }
    }
    public get genderCode(): string {
        return this.filters.clubGenderCode;
    }
    @Input() public isGenderCodeReadOnly = false;

    @Input() public allowCreateTemporaryClub = false;

    @Input() public value: ClubListItemDTO;
    @Output() public valueChange: EventEmitter<ClubListItemDTO> = new EventEmitter<ClubListItemDTO>();

    constructor(
        protected dataService: ClubDataService,
        protected notificationService: NotificationService,
        protected simpleStorageService: SimpleStorageService,
        protected injector: Injector) {
        super(injector, dataService, notificationService, simpleStorageService, CLUB_MINI_SEARCH_PAGE_SIZE);
    }

    public ngOnInit(): void {
        this.filters.context = this.contextCode;
        this.filters.showClubsForAllNf = this.showClubsForAllNf;
        super.ngOnInit();
    }

    public handleSelChange(event: SelectionEvent): void {
        super.handleSelChange(event);
        this.valueChange.emit(this.currentClubListItem);
    }

    public saveHandler(city: City): void {
        this.filters.name = this.clubCreated.officialName;
        this.selectedCity = city;
        this.filters.cityName = city.name;
        this.filters.country.setValue(this.clubCreated.country);
        super.filter();
        // Reset the initial lock on "create" button to prevent user recreating a club instead of picking the newly created one
        this.initialSearchDone = false;
    }

    public createNew(): void {
        this.clubCreated = {} as ClubDTO;
        this.clubCreated.cityName = this.filters.cityName;
        this.clubCreated.officialName = this.filters.name;
        this.clubCreated.displayName = this.filter.name;
        if (this.nationalFederationCountryId) {
            this.clubCreated.countryId = this.nationalFederationCountryId;
        } else if (this.filters.country.isSet()) {
            this.clubCreated.countryId = this.filters.country.getValue().countryId;
        }
    }

    public filter(): void {
        if (this.mergeMode == true) {
            this.filters.includeAllStatuses = true;
        }
        super.filter();
        this.initialSearchDone = true;
    }

}
